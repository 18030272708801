import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../pages/Home";
import { MapProvider } from "react-map-gl/maplibre";
import Login from "../components/auth/Login/Login";
import Register from "../components/auth/Register/Register";
import Verify from "../components/auth/Verify/Verify";
import Userupdate from "../components/auth/Update/Userupdate";
import User from "../components/auth/user/User";
import PrivateRoute from "./PrivateRoute";
import Panodirection from "../pages/panoDirection/Panodirection";
import Direction from "../components/directions/Direction";
import Directionroute from "../pages/Routing/Directionroute";

const Allroutes = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MapProvider>
            <Home />
          </MapProvider>
        }
      />
      {/* Dynamic route to handle /lat,long in the URL
      <Route
        path="/:lat([0-9.-]+),:lng([0-9.-]+)"
        element={
          <MapProvider>
            <Home />
          </MapProvider>
        }
      /> */}

      {/*  route to handle any lat,lng path */}
      <Route
        path="/*"
        element={
          <MapProvider>
            <Home />
          </MapProvider>
        }
      />
      <Route path="/directions" element={<Directionroute />} />
      <Route
        path="/panoroma-direction"
        element={
          <MapProvider>
            <Panodirection />
          </MapProvider>
        }
      />
      {/* <Route path="/login" element={<Login />} />
      <Route path="/verify" element={<Verify />} />
      <Route path="/register" element={<Register />} />
      <Route path="/update" element={<Userupdate />} /> */}
      {/* <Route path="/user" element={<User />} /> */}
      {/* <Route exact path="/" element={<PrivateRoute />}>
        <Route exact path="/user" element={<User />} />
      </Route> */}
    </Routes>
  );
};

export default Allroutes;
