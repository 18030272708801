import React, { useEffect, useState } from "react";
import { eventUrl, eventImageUrl } from "../../../urls/urls";
import { Button, Col, Modal, Row } from "react-bootstrap";
import "./event.scss";
import { Layer, Marker, Source } from "react-map-gl/maplibre";
import pin from "../../../assets/images/pin.png";
import calendar from "../../../assets/images/calendar.png";
import eventpin from "../../../assets/images/event-marker.png";
import maplibregl from "maplibre-gl";

import { useMapContext } from "../../context/Mapcontext";
import { useMap } from "react-map-gl/maplibre";
import pharmacy from "../../../assets/images/pharmacy-1marker.png";
import toilet from "../../../assets/images/toilet-1marker.png";
import start from "../../../assets/images/starting_point.png";
import end from "../../../assets/images/ending_point.png";
import water from "../../../assets/images/water_point.png";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [select, setSelect] = useState(null);

  const { id, setIdValue, clusterName, setClusterNameValue } = useMapContext();
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [show, setShow] = useState(false);
  const [view, setView] = useState(true);
  const [selectedInfo, setSelectedInfo] = useState(null);

  const handleClose = () => setShow(false);
  const url = eventUrl;
  const { current: map } = useMap();

  const lineStyle = {
    id: "route",
    type: "line",
    source: "route",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      // "line-color": "rgba(0,255,0,0.5)",
      "line-color": "#E37547",
      "line-width": 8,
    },
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch(url);
        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            // Filter events based on status, end date and isActive
            const filteredEvents = data.data.results.filter(
              (event) => event.isActive && event.isSpecial
            );

            setEvents(filteredEvents);

            // setEvents(data.data.results);
          } else {
            console.log("Failed to fetch events:", data.message);
          }
        } else {
          console.log("Failed to fetch events:", response.statusText);
        }
      } catch (error) {
        console.error("Failed to fetch events", error);
      }
    };

    fetchEvents();
  }, [url]);

  const singData = events.filter((event) => event.type === "single");
  // console.log(singData);
  useEffect(() => {
    if (singData && singData.length > 0) {
      singData
        .filter((singData) => singData._id === id)
        .map((singData) => {
          if (singData.points) {
            let pointData;
            try {
              pointData = JSON.parse(singData.points); // Parse the points data
            } catch (error) {
              console.error("Failed to parse points data", error);
              return [];
            }
            if (pointData && pointData.features) {
              // Loop through features to extract the coordinates
              pointData.features.forEach((point, index) => {
                const lat = point.geometry.coordinates[1]; // Latitude
                const lng = point.geometry.coordinates[0]; // Longitude

                // Perform map flyTo
                if (lat && lng) {
                  map.flyTo({
                    center: [lng, lat], // Longitude, Latitude
                    zoom: 18, // Set your desired zoom level
                    essential: true, // This ensures the animation is considered essential
                  });
                }
              });
            }
          }
          return [];
        });
    }
  }, [singData, id, map]); // Ensure `map` is available in the dependency array

  useEffect(() => {
    const selectedEvent = singData.find((event) => event._id === id);

    if (selectedEvent && selectedEvent.line) {
      try {
        const lines = JSON.parse(selectedEvent.line);

        if (lines && lines.features && lines.features.length > 0) {
          const bounds = new maplibregl.LngLatBounds();

          lines.features.forEach((feature) => {
            if (
              feature.geometry &&
              feature.geometry.type === "LineString" &&
              Array.isArray(feature.geometry.coordinates) &&
              feature.geometry.coordinates.length > 0
            ) {
              feature.geometry.coordinates.forEach((coord) => {
                if (Array.isArray(coord) && coord.length === 2) {
                  const [lng, lat] = coord;
                  if (typeof lng === "number" && typeof lat === "number") {
                    bounds.extend([lng, lat]);
                  } else {
                    console.warn("Invalid coordinate values: ", coord);
                  }
                } else {
                  console.warn("Invalid coordinate format: ", coord);
                }
              });
            } else {
              console.warn("Invalid or missing geometry in feature:", feature);
            }
          });

          if (bounds.isEmpty()) {
            console.warn("No valid coordinates to fit bounds.");
          } else {
            // zoom the map to fit the bounds of the GeoJSON layer
            map.fitBounds(bounds, { padding: 50 });
          }
        } else {
          console.log("No features available to create bounds.");
        }
      } catch (error) {
        console.error("Failed to parse GeoJSON:", error);
      }
    }
  }, [map]);

  useEffect(() => {
    if (map && id) {
      const selectedEvent = singData.find((event) => event._id === id);
      if (selectedEvent && selectedEvent.points) {
        try {
          const points = JSON.parse(selectedEvent.points);

          if (points && points.features && points.features.length > 0) {
            const coordinates = points.features.map(
              (feature) => feature.geometry.coordinates
            );

            const lats = coordinates.map((coord) => coord[1]);
            const lngs = coordinates.map((coord) => coord[0]);

            const bounds = [
              [Math.min(...lngs), Math.min(...lats)],
              [Math.max(...lngs), Math.max(...lats)],
            ];

            // Fit the map to the bounds with padding
            map.fitBounds(bounds, { padding: 50 });
          } else {
            console.error("No valid features found in GeoJSON data.");
          }
        } catch (error) {
          console.error("Error parsing GeoJSON data:", error);
        }
      } else {
        console.error("No points data found in selected event.");
      }
    }
  }, [map]);

  const handleButtonClick = (eventId) => {
    // setSelectedEventId((prevId) => (prevId === eventId ? null : eventId));
    setIdValue((prevId) => (prevId === eventId ? null : eventId));
  };

  const handleMarkerClick = (point) => {
    setSelectedPoint(point);
    setShow(true);
  };

  const handleInfoClick = (data) => {
    setSelectedInfo(data);
    setView(true);
  };

  // Close modal and reset state
  const handleCancel = () => {
    setView(false);
    // setSelectedPoint(null);
  };

  let selectedPointObject = {};

  return (
    <>
      <div className="event-box">
        {events.map((event, index) => {
          const imageUrl = `${eventImageUrl}/${encodeURIComponent(
            event.image.replace("public/", "")
          )}`;

          return (
            <div className="event-btn" key={index}>
              <Button
                onClick={() => handleButtonClick(event._id)}
                style={{
                  backgroundImage: `url(${imageUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                  // width: "100%",
                }}
              ></Button>
            </div>
          );
        })}

        {/* render the points as marker for single type events*/}
        {singData &&
          singData.length > 0 &&
          singData
            .filter((singData) => singData._id === id)
            .map((singData) => {
              if (singData.points) {
                let pointData;
                try {
                  pointData = JSON.parse(singData.points);
                } catch (error) {
                  console.error("Failed to parse points data", error);
                  return [];
                }
                if (pointData && pointData.features) {
                  return pointData.features.map((point, index) => (
                    <Marker
                      key={index}
                      latitude={point.geometry.coordinates[1]}
                      longitude={point.geometry.coordinates[0]}
                      onClick={() => handleMarkerClick(point)}
                    >
                      <div className="text-center">
                        {point.properties.icon ? (
                          point.properties.icon === "pharmacy" ? (
                            <img
                              style={{ height: "35px", width: "35px" }}
                              src={pharmacy} // Replace 'pharmacy' with the actual URL or import path of your pharmacy icon
                              alt="pharmacy"
                            />
                          ) : point.properties.icon === "toilet" ? (
                            <img
                              style={{ height: "35px", width: "35px" }}
                              src={toilet} // Replace 'toilet' with the actual URL or import path of your toilet icon
                              alt="toilet"
                            />
                          ) : point.properties.icon === "Starting Point" ? (
                            <img
                              style={{ height: "35px", width: "35px" }}
                              src={start} // Replace 'start' with the actual URL or import path of your starting point icon
                              alt="starting point"
                            />
                          ) : point.properties.icon === "Ending Point" ? (
                            <img
                              style={{ height: "35px", width: "35px" }}
                              src={end} // Replace 'end' with the actual URL or import path of your ending point icon
                              alt="ending point"
                            />
                          ) : point.properties.icon === "Water Point" ? (
                            <img
                              style={{ height: "35px", width: "35px" }}
                              src={water} // Replace 'water' with the actual URL or import path of your water point icon
                              alt="water point"
                            />
                          ) : (
                            <img
                              style={{ height: "30px", width: "30px" }}
                              src={pin} // Replace 'pin' with the actual URL or import path of your default pin image
                              alt="eventpin"
                            />
                          )
                        ) : (
                          <img
                            style={{ height: "30px", width: "30px" }}
                            src={pin} // Replace 'pin' with the actual URL or import path of your default pin image
                            alt="eventpin"
                          />
                        )}

                        <br />
                        <span style={{ fontWeight: "bolder" }}>
                          {point.properties.name}
                        </span>
                      </div>
                    </Marker>
                  ));
                }
                // return null;
              }
              return [];
            })}

        {/* render the lines for single type events*/}
        {singData &&
          singData.length > 0 &&
          singData
            .filter((singData) => singData._id === id)
            .map((singData) => {
              if (singData.line) {
                let lines;
                try {
                  lines = JSON.parse(singData.line);
                } catch (error) {
                  console.error("Failed to parse points data", error);
                  return [];
                }
                if (lines && lines.features) {
                  return lines.features.map((line, index) => (
                    <>
                      <Source type="geojson" data={lines}>
                        <Layer {...lineStyle} />
                      </Source>
                    </>
                  ));
                }
                // return null;
              }
              return [];
            })}

        {clusterName &&
          events
            .filter((event) => event._id === id)
            .map((event) => {
              if (event.points) {
                let points;
                try {
                  points = JSON.parse(event.points);
                } catch (error) {
                  console.error("Failed to parse points data", error);
                  return [];
                }

                if (points && points.features) {
                  // Set to track unique coordinates
                  const renderedCoordinates = new Set();

                  return points.features
                    .filter(
                      (point) =>
                        point.properties &&
                        point.properties.clustername === clusterName
                    )
                    .map((point, index) => {
                      const coordinates = point?.geometry?.coordinates;

                      // Handle case when coordinates are not available
                      if (!coordinates || coordinates.length < 2) {
                        return null;
                      }

                      // Create a unique string for the coordinates (latitude, longitude)
                      const coordKey = `${coordinates[1]},${coordinates[0]}`;

                      // If these coordinates have already been rendered, skip this point
                      if (renderedCoordinates.has(coordKey)) {
                        return null;
                      }

                      // Add coordinates to the set to track them
                      renderedCoordinates.add(coordKey);

                      return (
                        <Marker
                          key={index}
                          latitude={coordinates[1]}
                          longitude={coordinates[0]}
                          onClick={() => handleMarkerClick(point)}
                        >
                          <div className="text-center">
                            {point.properties.icon ? (
                              point.properties.icon === "pharmacy" ? (
                                <img
                                  style={{ height: "35px", width: "35px" }}
                                  src={pharmacy}
                                  alt="pharmacy"
                                />
                              ) : point.properties.icon === "toilet" ? (
                                <img
                                  style={{ height: "35px", width: "35px" }}
                                  src={toilet}
                                  alt="toilet"
                                />
                              ) : point.properties.icon === "Starting Point" ? (
                                <img
                                  style={{ height: "35px", width: "35px" }}
                                  src={start}
                                  alt="starting point"
                                />
                              ) : point.properties.icon === "Ending Point" ? (
                                <img
                                  style={{ height: "35px", width: "35px" }}
                                  src={end}
                                  alt="ending point"
                                />
                              ) : point.properties.icon === "Water Point" ? (
                                <img
                                  style={{ height: "35px", width: "35px" }}
                                  src={water}
                                  alt="water point"
                                />
                              ) : (
                                <img
                                  style={{ height: "30px", width: "30px" }}
                                  src={pin}
                                  alt="eventpin"
                                />
                              )
                            ) : (
                              <img
                                style={{ height: "30px", width: "30px" }}
                                src={pin}
                                alt="eventpin"
                              />
                            )}
                            <br />
                            <span style={{ fontWeight: "bolder" }}>
                              {point.properties.name}
                            </span>
                          </div>
                        </Marker>
                      );
                    });
                }
              }
              return [];
            })}

        {/* render popup modal for info of selected point/marker */}
        {selectedPoint && (
          <Modal show={show} onHide={handleClose} size="md">
            <Modal.Header closeButton>
              <Modal.Title>{selectedPoint.properties.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                {selectedPoint.properties.image ? (
                  <img
                    style={{ objectFit: "cover" }}
                    src={selectedPoint.properties.image}
                    alt="event"
                  />
                ) : (
                  ""
                )}
              </div>
              <p style={{ marginTop: "1rem" }}>
                {selectedPoint.properties.info}
              </p>

              <div className="location-date">
                {selectedPoint.properties.date ? (
                  <Row>
                    <Col md={1}>
                      <img src={calendar} alt="calendar" />
                    </Col>
                    <Col md={11}>
                      <span>{selectedPoint.properties.date}</span>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {selectedPoint.properties.location ? (
                  <Row>
                    <Col md={1}>
                      <img
                        style={{ height: "20px", width: "20px" }}
                        src={eventpin}
                        alt="eventpin"
                      />
                    </Col>
                    <Col md={11}>
                      <span
                        style={{
                          fontSize: "0.8rem",
                          fontWeight: "bold",
                        }}
                      >
                        {selectedPoint.properties.location}
                      </span>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>

              <div className="btn-section">
                {selectedPoint.properties.callToAction ? (
                  <Button href={selectedPoint.properties.link}>
                    <span>{selectedPoint.properties.callToAction}</span>
                  </Button>
                ) : (
                  ""
                )}

                <Button>
                  <span style={{ textTransform: "uppercase" }}>share</span>
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Events;
