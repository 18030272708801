import React, { createContext, useContext, useState } from "react";

const MapContext = createContext();

export const MapProvider = ({ children }) => {
  const [latPoints, setLatPoints] = useState(null);
  const [longPoints, setLongPoints] = useState(null);
  const [isBooleanValue, setIsBooleanValue] = useState(false);
  const [cross, setCross] = useState(false);
  const [amenityWord, setAmenityWord] = useState("");
  const [list, setList] = useState(false);
  const [yawData, setYawData] = useState(null); // State for storing yawData
  const [pitchData, setPitchData] = useState(null);
  const [direction, setDirection] = useState(false);
  const [panoOn, setPanoOn] = useState(false);
  const [isPointClicked, setIsPointClicked] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(null);
  const [id, setId] = useState(null);
  const [clusterName, setClusterName] = useState(null);

  const setLatPointsValue = (value) => {
    setLatPoints(value);
  };

  const setLongPointsValue = (value) => {
    setLongPoints(value);
  };

  const setClusterNameValue = (value) => {
    setClusterName(value);
  };

  const setIdValue = (value) => {
    setId(value);
  };

  const setClickedIndexValue = (value) => {
    setClickedIndex(value);
  };

  const setIsPointClickedValue = (value) => {
    setIsPointClicked(value);
  };

  const setPanoOnValue = (value) => {
    setPanoOn(value);
  };

  const setDirectionValue = (value) => {
    setDirection(value);
  };

  const setMapCoordinates = (lat, lng) => {
    setLatPoints(lat);
    setLongPoints(lng);
  };

  const setBooleanValue = (value) => {
    setIsBooleanValue(value);
  };

  const setListValue = (value) => {
    setList(value);
  };

  const setCrossValue = (value) => {
    setCross(value);
  };

  const setAmenityValue = (value) => {
    setAmenityWord(value);
  };

  const setYawDataValue = (value) => {
    // Function to set yawData
    setYawData(value);
  };

  const setPitchDataValue = (value) => {
    setPitchData(value);
  };

  return (
    <MapContext.Provider
      value={{
        setLatPointsValue,
        latPoints,
        setLongPointsValue,
        longPoints,
        setClusterNameValue,
        clusterName,
        setIdValue,
        id,
        isPointClicked,
        setIsPointClickedValue,
        direction,
        setDirectionValue,
        pitchData,
        setPitchDataValue,
        yawData,
        setYawDataValue,
        latPoints,
        longPoints,
        setMapCoordinates,
        isBooleanValue,
        setBooleanValue,
        cross,
        setCrossValue,
        amenityWord,
        setAmenityValue,
        list,
        setListValue,
        panoOn,
        setPanoOnValue,
        clickedIndex,
        setClickedIndexValue,
      }}
    >
      {children}
    </MapContext.Provider>
  );
};

export const useMapContext = () => {
  return useContext(MapContext);
};
